<template>
  <loading
    :active="isLoadingModel"
    :can-cancel="true"
    color="#ffc107"
    :is-full-page="fullPage"
  ></loading>

  <div class="grid p-fluid">
    <div class="col-12 md:col-12">
      <div class="card">
        <h5>
          Profile - (
          <b style="color: green"
            >{{ editprofile.firsT_NAME }}{{ editprofile.middeL_NAME
            }}{{ editprofile.surname }}</b
          >)
        </h5>
        <hr />
        
        <Toast />
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-4">
            <label for="firsT_NAME"><b>First Name</b></label>
            <InputText
              id="firsT_NAME"
              type="text"
              v-model.trim="editprofile.firsT_NAME"
              required="true"
              autofocus
            />
          </div>
          <div class="field col-12 md:col-4">
            <label for="middeL_NAME"><b>Middle Name</b></label>
            <InputText
              id="middeL_NAME"
              type="text"
              v-model.trim="editprofile.middeL_NAME"
              required="true"
              autofocus
            />
          </div>
          <div class="field col-12 md:col-4">
            <label for="surname"><b>Surname Name</b></label>
            <InputText
              id="surname"
              type="text"
              v-model.trim="editprofile.surname"
              required="true"
              autofocus
            />
          </div>

          <div class="field col-12 md:col-3">
            <label for="state"><b>state</b></label>
            <InputText
              id="state"
              type="text"
              v-model.trim="editprofile.state"
              required="true"
              autofocus
            />
          </div>
          <div class="field col-12 md:col-3">
            <label for="disrtict"> <b>Disrtict</b></label>
            <InputText
              id="disrtict"
              type="text"
              v-model.trim="editprofile.disrtict"
              required="true"
              autofocus
            />
          </div>

          <div class="field col-12 md:col-3">
            <label for="taluka"><b>Taluka</b></label>
            <InputText
              id="taluka"
              type="text"
              v-model.trim="editprofile.taluka"
              required="true"
              autofocus
            />
          </div>

          <div class="field col-12 md:col-3">
            <label for="citY_VILLAGE"><b>City/Village</b></label>
            <InputText
              id="citY_VILLAGE"
              type="text"
              v-model.trim="editprofile.citY_VILLAGE"
              required="true"
              autofocus
            />
          </div>
          <div class="field col-12 md:col-3">
            <label for="pincode"><b>Pincode</b></label>
            <InputText
              id="pincode"
              type="text"
              v-model.trim="editprofile.pincode"
              required="true"
              autofocus
            />
          </div>
          <div class="field col-12 md:col-3">
            <label for="aadhaR_CARD"><b>Adhar Card</b></label>
            <InputText
              id="aadhaR_CARD"
              type="text"
              v-model.trim="editprofile.aadhaR_CARD"
              required="true"
              autofocus
            />
          </div>
          <div class="field col-12 md:col-3">
            <label for="mobile"><b>Mobile</b></label>
            <InputText
              id="mobile"
              type="text"
              v-model.trim="editprofile.mobile"
              required="true"
              autofocus
            />
          </div>
          <div class="field col-12 md:col-3">
            <label for="email"><b>Email</b></label>
            <InputText
              id="email"
              type="text"
              v-model.trim="editprofile.email"
              required="true"
              autofocus
            />
          </div>

          <div class="field col-12 md:col-12">
            <label for="address"><b>Address</b></label>
            <Textarea
              id="address"
              type="text"
              v-model.trim="editprofile.address"
              required="true"
              autofocus
            />
          </div>

          <div class="field col-12 md:col-3">
            <label for="batchname"><b>Batch</b></label>
            <InputText
              id="batchname"
              type="text"
              v-model.trim="editprofile.batchname"
              required="true"
              autofocus
              disabled
            />
          </div>

          <div class="field col-12 md:col-3">
            <label for="subjectname"><b>Subject</b></label>
            <InputText v-if="editprofile.subjectname"
              id="subjectname"
              type="text"
              v-model.trim="editprofile.subjectname[0].subjectname"
              required="true"
              autofocus
              disabled
            />
          </div>
          <div class="field col-12 md:col-2"></div>

          <div class="field col-12 md:col-2">
            <label><b></b></label>
            <Button
              label="UPDATE"
              icon="pi pi-check"
              class="p-button-success mr-1 mb-1"
              @click="updateProfile"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "../service/ProductService";
//import FormNewsMaster from "@/components/FormNewsMaster";
//import * as Survey from "survey-vue";

// import axios from "axios";
import apis from "@/apis";
import axios from "axios";

export default {
  data() {
    return {
      isLoading: false,
      isLoadingModel: false,
      loading: false,
      fullPage: true,
      file_attachment: "",
      dropdownItems: [
        { name: "Motivational", value: "Motivational" },
        { name: "Interview", value: "Interview" },
      ],
      dropdownItems2: [
        { name: "Active", value: "Active" },
        { name: "InActive", value: "InActive" },
        { name: "Deleted", value: "Deleted" },
      ],
      products: null,
      editprofile: [],
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      lazyParams: {},
      product: {},
      selectedProducts: null,
      filters: {},
      offset: 1,
      selectstatus: { name: "Active", value: "Active" },
      selectstatus2: { name: "Motivational", value: "Motivational" },
      limit: 10,
      columns: [],
      head: [
        "Data Usages",
        "Network Connectivity",
        "Students",
        "Teachers",
        "Student Registration",
        "News Master",
        "Government Panel",
        "Feedback Master",
        "User Queries",
        "Schemes Master",
        "Notice Master",
        "Subject Master",
        "Teacher Schedule Master",
        "Teacher Schedule DE Master",
        "Query Types Master",
        "Sub Query Types Master",
        "Admin Users",
        "Application Roles",
        "Departments",
        "Cast Master",
        "Year Master",
        "District Master",
        "Student Attendance",
        "Student Document",
        "Teachers",
        "Radical ids",
        "Subject Topics",
        "Query Comments",
        "Student Submit Answers",
        "Mock Tests",
        "Mock Test Papers",
        "Mock Test Results",
        "Video",
        "Syllabus",
        "Course",
      ],
      pageno: 1,
      submitted: false,
      totalRecords: 0,
      id: "",
      statuses: [
        { label: "INSTOCK", value: "instock" },
        { label: "LOWSTOCK", value: "lowstock" },
        { label: "OUTOFSTOCK", value: "outofstock" },
      ],
    };
  },
  productService: null,
  async created() {
    this.productService = new ProductService();
    this.initFilters();
  },
  async mounted() {
    // this.productService.getProducts().then(data => this.products = data);
    this.id = this.$route.params.id;

    this.lazyParams = {
      first: 0,
      rows: this.limit,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    if (this.totalRecords == 0) {
      await this.get_count();
    }
    await this.get_list();
    //alert(this.id);
  },
  watch: {
    async $route() {
      this.products = [];
      this.columns = [];
      this.totalRecords = 0;
      this.id = this.$route.params.id;
      if (this.id) {
        await this.get_count();
        this.get_list();
      }
    },
  },
  methods: {
    handleFileUpload() {
      this.file_attachment = this.$refs.file_attachment.files[0];

      //alert(this.$refs.file_attachment.files[0].name);
    },
    async onPage(event) {
      this.lazyParams = event;
      this.page_no = event.page + 1;
      this.limit = event.rows;
      // console.log(event);
      await this.get_count();
      if (this.totalRecords > 0) {
        this.get_list();
      }
    },
    get_count: function () {
      // var data = {
      //   count: true,
      // };
      // this.loading = true;
      // var promise = apis.assprofiledetails(data, this.id);
      // promise.then((response) => {
      //   this.loading = false;
      //   console.log(response);
      //   this.totalRecords = response.data;
      //   //alert(this.totalRecords);
      // });
      // console.log(data);
    },
    get_list: function () {
      var data = {
        TEACHER_ID: localStorage.getItem("id"),
      };

      this.isLoadingModel = true;
      var promise = apis.assprofiledetails(data, this.id);
      promise.then((response) => {
        this.isLoadingModel = false;
        console.log("-------------------");
        console.log(response);
        this.products = response.data;
        this.editprofile = response.data;
        console.log(this.editprofile);
        if (this.columns.length == 0) {
          if (this.products.length > 0) {
            this.columns = Object.keys(this.products[0]);
          }
        }
      });

      console.log(data);
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    async updateProfile() {
      this.submitted = true;
    if(!this.editprofile.firsT_NAME)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter First Name",
            life: 3000,
          });
          return false;
    }
    if(!this.editprofile.middeL_NAME)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Middle Name",
            life: 3000,
          });
          return false;
    }
    if(!this.editprofile.surname)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Surname Name",
            life: 3000,
          });
          return false;
    }
    if(!this.editprofile.state)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter state",
            life: 3000,
          });
          return false;
    }
    
    if(!this.editprofile.disrtict)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Disrtict",
            life: 3000,
          });
          return false;
    }
    if(!this.editprofile.citY_VILLAGE)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter City/Village",
            life: 3000,
          });
          return false;
    }
    if(!this.editprofile.pincode)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Pincode",
            life: 3000,
          });
          return false;
    }
    if(!this.editprofile.aadhaR_CARD)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Adhar Card",
            life: 3000,
          });
          return false;
    }
    if(!this.editprofile.mobile)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Mobile",
            life: 3000,
          });
          return false;
    }
    if(!this.editprofile.email)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Email",
            life: 3000,
          });
          return false;
    }
    if(!this.editprofile.address)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Address",
            life: 3000,
          });
          return false;
    }

  //edit
      if (this.editprofile.teacheR_ID) {
        var data = {
          teacher_id: this.editprofile.teacheR_ID,
          firsT_NAME: this.editprofile.firsT_NAME,
          middeL_NAME: this.editprofile.middeL_NAME,
          surname: this.editprofile.surname,
          address: this.editprofile.address,
          state: this.editprofile.state,
          disrtict: this.editprofile.disrtict,
          taluka: this.editprofile.taluka,
          citY_VILLAGE: this.editprofile.citY_VILLAGE,
          mobile: this.editprofile.mobile,
          email: this.editprofile.email,
          password: this.editprofile.password,
          pincode: this.editprofile.pincode,

           batch: this.editprofile.batch,
           batchname: this.editprofile.batchname ,

          aadhaR_CARD: this.editprofile.aadhaR_CARD,
         status: "Active",
          createD_ON: this.editprofile.createD_ON,
          createD_BY: this.editprofile.createD_BY,
          updateD_ON: this.editprofile.updateD_ON,
          updateD_BY: localStorage.getItem("full_name"),
          teacheR_DE_ID: 1,
        };
        this.isLoadingModel = true;
        var promise = apis.editassteachermaster(data);
        promise
          .then((responseapi) => {
            this.$swal(responseapi.data.message);
            this.isLoadingModel = false;
            this.productDialog = false;
            this.get_list();
            this.get_count();
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }
    },
    saveProduct() {
      this.submitted = true;
      if (this.product.name.trim()) {
        if (this.product.id) {
          this.product.inventoryStatus = this.product.inventoryStatus.value
            ? this.product.inventoryStatus.value
            : this.product.inventoryStatus;
          this.products[this.findIndexById(this.product.id)] = this.product;
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Updated",
            life: 3000,
          });
        } else {
          this.product.id = this.createId();
          this.product.code = this.createId();
          this.product.image = "product-placeholder.svg";
          this.product.inventoryStatus = this.product.inventoryStatus
            ? this.product.inventoryStatus.value
            : "INSTOCK";
          this.products.push(this.product);
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Created",
            life: 3000,
          });
        }
        this.productDialog = false;
        this.product = {};
      }
    },
    editProduct(product) {
      this.file_attachment = "";
      this.product = { ...product };
      this.productDialog = true;
      // this.selectstatus.value = this.product.type;
      // this.selectstatus.name = this.product.type;

      // this.selectstatus2.value = this.product.status;
      // this.selectstatus2.name = this.product.status;

      // this.file_attachment = this.product.image;
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      this.products = this.products.filter((val) => val.id !== this.product.id);
      this.deleteProductDialog = false;
      this.product = {};
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Product Deleted",
        life: 3000,
      });
    },

    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(
        (val) => !this.selectedProducts.includes(val)
      );
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Products Deleted",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
